<template>
  <div class="pages">
    <div class="tables">
      <div class="tits">
        <div class="titsLeft">
          <p class="po-r" ref="titsLeft">
            <span class="text-right">元音</span>
            <span></span>
            <span class="text-left">辅音</span>
          </p>
        </div>
        <div class="titsRight" ref="titsRight">
          <div>
            <p v-for="(item, i) in cheatList.vowels" :key="i">
              <!-- 表头{{ i + 1 }} -->
              <span class="d-block">{{ item.syllables }}</span>
              <span class="d-block">[{{ item.rome }}]</span>
            </p>
          </div>
        </div>
      </div>

      <div
        class="tbody"
        @scroll="scrollEvent($event)"
        ref="tbodyRight"
        @touchstart="touchstart"
        @touchmove="touchmove"
        @touchend="touchend"
      >
        <div class="tbodyLeft" ref="tbodyLeft">
          <div ref="tbodyLeftItem">
            <p
              v-for="(item, i) in cheatList.consonants"
              :key="i"
              class="text-center"
            >
              <span class="d-block">{{ item.syllables }}</span>
              <span class="d-block">[{{ item.rome }}]</span>
            </p>
          </div>
        </div>
        <div class="tbodyRight">
          <div
            v-for="(item, i) in cheatList.quickChecks"
            :key="i"
            :class="['row', idx == i ? 'rowActive' : '']"
          >
            <p
              :class="idx == i && idx1 == i1 ? 'active' : ''"
              v-for="(item1, i1) in item"
              :key="i1"
              @click.stop="check(item, i, item1, i1)"
            >
              <span class="d-block"> {{ item1.word }}</span>
              <span class="d-block">{{ item1.rome }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showDown">
      <downBtn :isWx="isWx" @down="download" @wechatShow="wechatShow"></downBtn>
    </div>
  </div>
</template>

<script>
import downBtn from '@/components/downBtn'
import mixin from '@/utils/mixin'
import { getShorthandTable } from '@/api/fortyTone'
export default {
  name: 'tables',
  data() {
    return {
      /* 移动所需参数 */
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      isMove: false,

      idx: -1,
      idx1: -1,
      cheatList: [],
      audio: new Audio(),
      sp: {},
      showDown: false,
    }
  },
  mixins: [mixin],
  components: {
    downBtn,
  },
  created() {
    if (this.$route.query.isApp) {
      this.showDown = false
    } else {
      this.showDown = true
    }
    this.getShorthandTable()
  },
  methods: {
    async getShorthandTable() {
      const { data, code } = await getShorthandTable()
      if (code != 'SUCCESS') return
      data.vowels = data.vowels.sort((a, b) => a.id - b.id)
      data.quickChecks.forEach((item) => {
        let con = this.sp[`${item.consonantId}`]
        con = con ? con : (con = {})
        this.sp[`${item.consonantId}`] = con
        con[`${item.vowelId}`] = item
      })
      this.cheatList = {
        ...data,
      }
      this.cheatList.quickChecks = this.objKeySort(this.sp, data.consonants)
      // this.$toast.fail({ message: 'failfailfail' })
    },
    objKeySort(arys, arr) {
      //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获 取的属性名进行排序，newkey是一个数组
      // var newkey = Object.keys(arys).sort()
      //console.log(‘newkey=’+newkey);
      var newObj = [] //创建一个新的对象，用于存放排好序的键值对
      arr.map((item) => {
        //遍历newkey数组
        // newObj[`${item}`] = arys[0][item]
        newObj.push(arys[item.id])

        //向新创建的对象中按照排好的顺序依次增加键值对
      })
      // console.log(arr1)
      return newObj //返回排好序的新对象
    },
    chunk(array, size) {
      //获取数组的长度，如果你传入的不是数组，那么获取到的就是undefined
      const length = array.length
      //判断不是数组，或者size没有设置，size小于1，就返回空数组
      if (!length || !size || size < 1) {
        return []
      }
      //核心部分
      let index = 0 //用来表示切割元素的范围start
      let resIndex = 0 //用来递增表示输出数组的下标

      //根据length和size算出输出数组的长度，并且创建它。
      let result = new Array(Math.ceil(length / size))
      //进行循环
      while (index < length) {
        //循环过程中设置result[0]和result[1]的值。该值根据array.slice切割得到。
        result[resIndex++] = array.slice(index, (index += size))
      }
      //输出新数组
      return result
    },
    check(item, i, item1, i1) {
      this.idx = i
      this.idx1 = i1
      // console.log(item, i, item1, i1, 'iiiii')
      this.audio.pause()
      this.audio = new Audio(item1.audio)
      this.audio.play()
    },
    scrollEvent(e) {
      // console.log(e.target.scrollLeft, 'left')
      // console.log(this.$refs.titsLeft.offsetHeight)
      this.$refs.titsRight.style.left =
        -e.target.scrollLeft + this.$refs.titsLeft.offsetHeight + 'px'
      this.$refs.tbodyLeftItem.style.top = -e.target.scrollTop + 'px'
    },

    /* 监听滑动开始 */
    touchstart(e) {
      /* 阻止一些默认事件 */
      // e.preventDefault();
      /* 记录初始位置 */
      this.startX = e.touches[0].pageX
      this.startY = e.touches[0].pageY
      // console.log(this.startX, this.startY)
    },
    /* 监听滑动移动 */
    touchmove(e) {
      /* 判断是否滚动 */
      this.isMove = true
      /* 监听滑动最终结束的位置 */
      this.endX = e.touches[0].pageX
      this.endY = e.touches[0].pageY

      /* 判断移动方向 */
      let X = this.endX - this.startX,
        Y = this.endY - this.startY
      /* 判断是否移动还是点击 */
      if (this.isMove) {
        if (X > 0 && Math.abs(X) > Math.abs(Y)) {
          //向右
          // console.log('向右')
          this.$refs.tbodyRight.style['overflowY'] = 'hidden'
          this.$refs.tbodyRight.style['overflowX'] = 'auto'
        } else if (X < 0 && Math.abs(X) > Math.abs(Y)) {
          //向左
          // console.log('向左')
          this.$refs.tbodyRight.style['overflowY'] = 'hidden'
          this.$refs.tbodyRight.style['overflowX'] = 'auto'
        } else if (Y > 0 && Math.abs(Y) > Math.abs(X)) {
          //向下
          // console.log('向下')
          this.$refs.tbodyRight.style['overflowX'] = 'hidden'
          this.$refs.tbodyRight.style['overflowY'] = 'auto'
        } else if (Y < 0 && Math.abs(Y) > Math.abs(X)) {
          //向上
          // console.log('向上')
          this.$refs.tbodyRight.style['overflowX'] = 'hidden'
          this.$refs.tbodyRight.style['overflowY'] = 'auto'
        } else {
          //没有
          // console.log("没有");
        }
      } else {
        // console.log("没有");
      }
    },
    /* 监听滑动结束 */
    touchend(e) {},
  },
}
</script>
<style scoped lang="scss">
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
.pages {
  height: 100vh;
  box-sizing: border-box;
  p {
    width: 64px;
    height: 64px;
    text-align: center;
    // line-height: 64px;
    flex-shrink: 0;
    border-right: 1px solid #f6f6f6;
    box-sizing: border-box;
    &.active {
      background-color: #638aff !important;
      color: #fff;
    }
  }
  .tables {
    width: 100%; //自定义表格整体宽度
    font-size: 12px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    // border-right: 1px solid red;
    // border-bottom: 1px solid red;
    // border-bottom: 1px solid #F6F6F6;
    .tbody {
      height: calc(100vh - 64px); //自定义表格内容高度
      overflow: auto;
    }
    > div {
      display: flex;
    }
    div {
      flex-shrink: 0;
    }
    .tits {
      height: 64px;
      // padding-left: 64px;
      // margin-left: 64px;
      background: #fff;
    }
    .titsLeft,
    .tbodyLeft {
      width: 64px;
    }
    .titsRight,
    .tbodyRight {
      width: 250px; //自定义表头表体内容宽度
    }
    .titsLeft {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 97;
      p {
        width: 64px;
        background-color: #638aff;
        // background: rgba(0, 0, 0, 0.4);
        color: #fff;
        // opacity: 0.5;
        border-bottom: 1px solid #f6f6f6;
        overflow: hidden;

        span {
          font-size: 16px;
          display: block;
          line-height: 22px;

          &:first-child {
            padding-right: 3px;
          }
          &:nth-child(2) {
            &::after {
              position: absolute;
              content: '';
              width: 100px;
              height: 100px;
              top: 20px;
              left: -81%;
              border-right: 1px solid #fff;
              // border-bottom: 1px solid #f00;
              transform: rotate(-45deg);
            }
          }
          &:last-child {
            position: absolute;
            bottom: 0;
            left: 3px;
          }
        }
      }
    }
    .titsRight {
      height: 64px;
      position: absolute;
      top: 0;
      left: 64px;
      // margin-left: 9px;
      div {
        display: flex;
        right: 32px;
        font-size: 16px;
        p {
          background-color: #638aff;
          color: #fff;
          border-bottom: 1px solid #f6f6f6;
          padding: 4px 0;
          span {
            line-height: 28px;
          }
        }
      }
    }
    .tbodyLeft {
      // overflow: hidden;
      white-space: nowrap;
      height: 100%;
      background-color: #fff;
      div {
        margin-top: 64px;
        width: 64px;
        background-color: #fff;
        left: 0;
        top: 0px;
        position: absolute;
        overflow: hidden;
        font-size: 16px;
        p {
          border-bottom: 1px solid #f6f6f6;
          background: #638aff;
          color: #fff;
          // border: 1px solid #F6F6F6;
          padding: 4px 0;
          span {
            line-height: 28px;
          }
        }
        // padding-top: 40px;
        // bottom: 17px; //避开下方滚动条位置
      }
    }
    .tbodyRight {
      white-space: nowrap;
      background-color: none;
      display: flex;
      flex-direction: column;
      .row {
        display: flex;
        font-size: 14px;
        &.rowActive {
          p {
            background: #f2f5ff;
          }
        }
        p {
          border-bottom: 1px solid #f6f6f6;
          padding: 4px 0;
          span {
            line-height: 28px;
          }
        }
      }
    }
  }
}
</style>
